import React, { Suspense, useEffect } from 'react';
import './App.css';
import Base from './Layouts/base';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {Navigate} from "react-router-dom";


// Auth
const Login = React.lazy(() => import('../src/Views/Auth/login'));
const Register = React.lazy(() => import('../src/Views/Auth/register'));


// Foundation
const FoundationLists = React.lazy(() => import('../src/Views/Foundation/Lists'));
const FoundationPlanets = React.lazy(() => import('./Views/Foundation/Planets'));
const FoundationCategories = React.lazy(() => import('../src/Views/Foundation/Categories'));
const FoundationVideos = React.lazy(() => import('../src/Views/Foundation/Videos'));

const RedirectRoute = () => {
    useEffect(() => {
        window.location.href = '/login'; // Redirects to the login page
    }, []);

    return null;
};

function App() {
  return (
      <React.Fragment>
        <Router basename="/">
          <Suspense fallback={<div>Loading....</div>}>
            <Routes>
              {/* Render the Base component which will handle the layout and nested routes */}
                <Route path="/" element={<RedirectRoute />} />

                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />

                <Route path="*" element={<Base />} />

                <Route exact path="/foundations" name="Login Page" element={<FoundationLists />} />
                <Route exact path="/planets" name="Login Page" element={<FoundationPlanets />} />
                <Route exact path="/categories/:categoryId" name="Login Page" element={<FoundationCategories />} />
                <Route exact path="/videos" name="Login Page" element={<FoundationVideos />} />
            </Routes>
          </Suspense>
        </Router>
      </React.Fragment>
  );
}

export default App;
