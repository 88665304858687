import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

// Lazy load components
// const Home = lazy(() => import("./pages/home.html"));


const Dashboard = lazy(() => import("./Views/Dashboard"));

const Mentors = lazy(() => import("./Views/Mentors/mentors"));

const Courses = lazy(() => import("./Views/Courses/Lists"));
const CourseDetails = lazy(() => import("./Views/Courses/CourseDetails"));

const Services = lazy(() => import("./Views/Services/Lists"));
const ServicesMentors = lazy(() => import("./Views/Services/Mentors/Lists"));
const SandboxLists = lazy(() => import("./Views/Services/Sandbox/Lists"));
const Game = lazy(() => import("./Views/Services/Sandbox/Game"));
const LeaderBoardLists = lazy(() => import("./Views/Services/LeaderBoard/Lists"));
const ChatLists = lazy(() => import("./Views/Services/Chats/Lists"));
const BookmarkLists = lazy(() => import("./Views/Services/Bookmarks/Lists"));
const PDFLists = lazy(() => import("./Views/Services/PDF/Lists"));
const DownloadLists = lazy(() => import("./Views/Services/Downloads/Lists"));
const AttachmentsLists = lazy(() => import("./Views/Services/Downloads/Attachments"));
const PDFViewer = lazy(() => import("./Components/PDF/pdfViewer"));
const CalendarLists = lazy(() => import("./Views/Services/Calendar/Lists"));
const StockScreenerLists = lazy(() => import("./Views/Services/StockScreener/Lists"));
const StockScreenerTableData = lazy(() => import("./Views/Services/StockScreener/TableData"));
const PodcastLists = lazy(() => import("./Views/Services/Podcast/Lists"));
const CourseLiveClassesLists = lazy(() => import("./Views/Services/CourseLiveClasses/Lists"));
const CourseLiveSession = lazy(() => import("./Views/Services/CourseLiveClasses/LiveSession"));

const Profile = lazy(() => import("./Views/User/Profile"));

// const FoundationLists = lazy(() => import("./Views/Foundation/Lists"));

const VideosLists = lazy(() => import("./Views/Courses/Videos/Lists"));
const LiveSessionLists = lazy(() => import("./Views/LiveSession/Lists"));
// Define the routes
export const Routes = [
    // {
    //     path: "/",
    //     component: () => <Navigate to="/home.html" />,
    //     exact: true,
    // },
    // {
    //     path: "/features",
    //     component: () => <Navigate to="/features.html" />,
    //     exact: true,
    // },
    {
        path: "/dashboard",
        component: Dashboard,
        exact: true
    },
    // {
    //     path: "/foundation/lists",
    //     component: FoundationLists,
    //     exact: true
    // },
    {
        path: "/mentors",
        component: Mentors,
        exact: true
    },
    {
        path: "/courses",
        component: Courses,
        exact: true
    },
    {
        path: "/courses/mentor/:mentorId",
        component: Courses,
        exact: true
    },
    {
        path: "/course/:courseIdParam/details",
        component: CourseDetails,
        exact: true
    },
    {
        path: "/services",
        component: Services,
        exact: true
    },
    {
        path: "/services/mentors",
        component: ServicesMentors,
        exact: true
    },
    {
        path: "/services/live_session",
        component: CourseLiveClassesLists,
        exact: true
    },
    {
        path: "/services/:meetingId/course_meeting",
        component: CourseLiveSession,
        exact: true
    },
    {
        path: "/services/:liveSessionId/live_session",
        component: CourseLiveSession,
        exact: true
    },
    {
        path: "/services/sandbox",
        component: SandboxLists,
        exact: true
    },
    {
        path: "/services/sandbox/game",
        component: Game,
        exact: true
    },
    {
        path: "/services/leaderboard",
        component: LeaderBoardLists,
        exact: true
    },
    {
        path: "/services/chats",
        component: ChatLists,
        exact: true
    },
    {
        path: "/services/bookmarks",
        component: BookmarkLists,
        exact: true
    },
    {
        path: "/services/pdf",
        component: PDFLists,
        exact: true
    },
    {
        path: "/services/downloads",
        component: DownloadLists,
        exact: true
    },
    {
        path: "/services/downloads/:id/attachments",
        component: AttachmentsLists,
        exact: true
    },
    {
        path: "/services/pdf_viewer",
        component: PDFViewer,
        exact: true
    },
    {
        path: "/services/calendar",
        component: CalendarLists,
        exact: true
    },
    {
        path: "/services/stocks_screener",
        component: StockScreenerLists,
        exact: true
    },
    {
        path: "/services/stocks_screener/:id/:type_name",
        component: StockScreenerTableData,
        exact: true
    },
    {
        path: "/services/podcast",
        component: PodcastLists,
        exact: true
    },
    {
        path: "/user/profile",
        component: Profile,
        exact: true
    },
    {
        path: "/courses/videos",
        component: VideosLists,
        exact: true
    },
    {
        path: "/live_session",
        component: LiveSessionLists,
        exact: true
    },
];
