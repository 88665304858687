// storageUtils.js
export const getUserId = () => {
    try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo ? userInfo.userId : null; // Return null if userInfo is not found
    } catch (error) {
        console.error('Error retrieving userId:', error);
        return null; // Return null if there's an error parsing the data
    }
};

export const getUserName = () => {
    try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo ? userInfo.name : null; // Return null if userInfo is not found
    } catch (error) {
        console.error('Error retrieving userName:', error);
        return null; // Return null if there's an error parsing the data
    }
};

export const getUserProfilePic = () => {
    try {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        return userInfo ? userInfo.profilePic : null; // Return null if userInfo is not found
    } catch (error) {
        console.error('Error retrieving userName:', error);
        return null; // Return null if there's an error parsing the data
    }
};
